import { mapState } from 'vuex'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrAddActivationOptionForm',
  computed: {
    ...mapState('option', ['option_groups'])
  }
})

export default class GtrAddActivationOptionForm extends GtrSuper {
    option_groups!: Record<string, any>

    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean|undefined

    data () {
      return {
        value2: false,
        submitting2: false,
        activation_option: {
          qty: null,
          price: null
        }
      }
    }

    get leadRetrievalActivatonsOptionGroup () {
      const [group] = (this.option_groups || []).filter(group => group.name === 'Lead Retrieval Activations')
      return group
    }

    get quantityOptions () {
      let quantityOptions: any = []
      for (let i = 1; i <= 100; i++) {
        const item = { text: i.toString(), value: i.toString() }
        quantityOptions.push(item)
      }
      quantityOptions.push({ text: 'Unlimited', value: 'Unlimited' })
      for (const itemIndex in this.leadRetrievalActivatonsOptionGroup.options) {
        const item = this.leadRetrievalActivatonsOptionGroup.options[itemIndex]
        quantityOptions = quantityOptions.filter((i) => i.text !== item.name)
      }
      return quantityOptions
    }

    @Watch('visible', { immediate: true })
    onVisibiltyChange (payload: any) {
      this.$data.value2 = payload
    }

    onClose () {
      this.$data.activation_option = {
        qty: null,
        price: null
      }
      const observer = (this.$refs.observerForm2 as any)
      observer.reset()
      this.$data.value2 = false
      this.$emit('close')
    }

    async submit () {
      const form = (this.$refs.newUserForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting2 = true
          const data = {
            data: {
              name: this.$data.activation_option.qty,
              details: { en: this.$data.activation_option.qty },
              pricing: [{ reg_type: '_default', tiered_pricing: { _default: { price: this.$data.activation_option.price, cancel_price: 0 } } }],
              display: 1,
              display_order: this.$data.activation_option.qty === 'Unlimited' ? 1000 : this.$data.activation_option.qty,
              visible: 1
            },
            option_group_uuid: this.leadRetrievalActivatonsOptionGroup.uuid,
            event_uuid: this.$route.params.event_uuid
          }
          await this.$store.dispatch('option/createOption', data)

          Container.get(Notification).success('Option successfully created.')
          this.$data.activation_option = {
            qty: null,
            price: null
          }
          this.onClose()
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting2 = false
        }
      }
    }
}
