import { Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import router from '@/bootstrap/router/router'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import EventService from '@/modules/common/services/event/event.service'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import ChangeLog from '@/modules/common/components/changelog/changelog.vue'
import moment from 'moment'
import 'moment-timezone'
import GtrAddActivationOptionForm from './add-activation-option.form.vue'

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'GtrLeadsSetup',
  computed: {
    ...mapState('option', ['option_groups']),
    ...mapState('event', ['event']),
    ...mapState('leads', ['changelog', 'currentlyDeployedLiveUUID', 'currentDevUUID', 'ordersSettings', 'ordersSettingsMetadata']),
    ...mapState('pricing', ['lr_pricing_tiers'])
  },
  components: {
    changelog: ChangeLog,
    'gtr-add-activation-option-form': GtrAddActivationOptionForm
  }
})

export default class GtrLeadsSetup extends GtrSuper {
  option_groups!: Record<string, any>
  ordersSettings!: Record<string, any>
  event!: Record<string, any>

  data () {
    return {
      forms: {
        showAddActivationOptionForm: false
      },
      loading: false,
      deviceOptions: [],
      mobileAppEditedOptions: [],
      devicesExtraContent: {
        proscanner: {
          description: 'GTR Leads™ with the device in a sled for quicker scanning.',
          image: require('@/assets/img/tracking/proscanner-icon.png')
        }
      },
      editedOptions: [],
      settings: {
        orders_active: true,
        orders_start_date: null,
        orders_end_date: null,
        orders_closed_verbiage: '',
        orders_charge_type: 'charge',
        orders_scan_format: 'qrcode',
        orders_statement_descriptor: null,
        orders_terms_and_conditions_enabled: false,
        orders_terms_and_conditions: null,
        orders_qualifiers: [],
        use_legacy_leads_pricing: false,
        phone_number_mask_enabled: true
      },
      qualifiersOnLoad: [],
      qualifierToAdd: '',
      qualifierToAddDialog: false,
      changeLogModel: {
        changeLog: [],
        currentDevUUID: null,
        currentlyDeployedLiveUUID: null
      },
      settingsVersion: null,
      registrationTypeKey: '_default',
      earlyBirdStart: null,
      earlyBirdDeadline: null,
      legacyPricing: false
    }
  }

  get showAddActivationOptionForm () {
    return this.$data.forms.showAddActivationOptionForm
  }

  @Watch('changelog')
  onChangeLogChange (payload: any) {
    if (payload) {
      this.$data.changeLogModel.changeLog = payload.data
    }
  }

  @Watch('currentDevUUID')
  onCurrentDevUUIDChange (payload: any) {
    if (payload.dev) {
      this.$data.changeLogModel.currentDevUUID = payload.dev.uuid
    }
  }

  @Watch('currentlyDeployedLiveUUID')
  onCurrentlyDeployedLiveUUIDChange (payload: any) {
    if (payload.live) {
      this.$data.changeLogModel.currentlyDeployedLiveUUID = payload.live.uuid
    }
  }

  private async fetchChangeLogData () {
    try {
      this.$data.loading = true
      const event_uuid = this.$route.params.event_uuid
      const changelogData = { type: 'orders_settings', subtype: 'default', event_uuid }
      const deployData = { type: 'orders_settings', sub_type: 'default', event_uuid }
      await this.$store.dispatch('leads/getChangelog', changelogData)
      await this.$store.dispatch('leads/getCurrentlyDeployedLiveUUID', deployData)
      await this.$store.dispatch('leads/getCurrentDevUUID', deployData)
    } catch (error) {
      if (error.status !== 404) {
        Container.get(ErrorHandlerService).error(error)
      }
    } finally {
      this.$data.loading = false
    }
  }

  async handleDeployed () {
    await this.fetchChangeLogData()
  }

  handleUpdateSettingsVersion (payload: any) {
    if (payload) {
      this.$data.settingsVersion = payload
    }
  }

  set earlyBirdEditedOptions (payload: any) {
    this.$data.deviceOptions = payload
  }

  get earlyBirdEditedOptions () {
    return this.$data.deviceOptions
  }

  @Watch('settings.use_legacy_leads_pricing')
  onUseLegacyLeadsPricingChange (payload: any) {
    this.earlyBirdEditedOptions = this.setEarlyBirdOptions(this.earlyBirdOptions)
  }

  @Watch('ordersSettingsMetadata', { immediate: true })
  onOrdersSettingsMetadata (payload: any) {
    if (!payload || typeof payload !== 'object') {
      return
    }
    if (Object.keys(payload).length) {
      this.$data.settings.orders_qualifiers = payload.Orders.orders_qualifiers.available_options
      this.$data.qualifiersOnLoad = this.chunkQualifiersOnLoad(payload.Orders.orders_qualifiers.available_options)
      this.$data.settings.orders_statement_descriptor = payload.Orders.orders_statement_descriptor.default
      this.$data.settings.orders_closed_verbiage = payload.Orders.orders_closed_verbiage.default
    }
  }

  @Watch('ordersSettings', { immediate: true })
  async onOrdersSettingsChange (payload: any) {
    if (payload?.orders_settings && Object.keys(payload.orders_settings).length) {
      const settings = payload.orders_settings
      if (!settings?.orders_qualifiers) {
        await this.fetchSettingsMetadata()
      }
      if (settings && settings.orders_statement_descriptor === 'Lead Retrieval order for {{Event Name}}') {
        settings.orders_statement_descriptor = '{{event_identifier}} - {{first_name}} {{last_name}} - {{company}}'
      }
      this.setSettingsAfterDataChange(settings)
    }
  }

  @Watch('settingsVersion', { deep: true })
  async onSettingsVersionChange (settings_uuid: string) {
    if (settings_uuid) {
      const response = await this.$store.dispatch('leads/fetchSettingsByUuid', {
        event_uuid: this.$route.params.event_uuid,
        settings_uuid
      })
      if (response) {
        const settings = response.data.page_data
        if (!settings?.orders_qualifiers) {
          await this.fetchSettingsMetadata()
        }
        this.setSettingsAfterDataChange(settings)
        this.$data.changeLogModel.currentDevUUID = settings_uuid
      }
      Container.get(Notification).success('Settings version successfully changed.')
    }
  }

  private setSettingsAfterDataChange (settings) {
    const ordersStartInvalid = settings?.orders_start_date === 'Invalid date'
    const ordersEndInvalid = settings?.orders_end_date === 'Invalid date'
    this.$data.settings.orders_active = settings?.orders_active ?? this.$data.settings.orders_active
    this.$data.settings.orders_start_date = ordersStartInvalid ? null : settings?.orders_start_date
    this.$data.settings.orders_end_date = ordersEndInvalid ? null : settings?.orders_end_date
    this.$data.settings.orders_charge_type = settings?.orders_charge_type ?? this.$data.settings.orders_charge_type
    this.$data.settings.orders_scan_format = settings?.orders_scan_format ?? this.$data.settings.orders_scan_format
    this.$data.settings.orders_statement_descriptor = settings?.orders_statement_descriptor ?? this.$data.settings.orders_statement_descriptor
    this.$data.settings.orders_terms_and_conditions_enabled = settings?.orders_terms_and_conditions_enabled ?? this.$data.settings.orders_terms_and_conditions_enabled
    this.$data.settings.orders_terms_and_conditions = settings?.orders_terms_and_conditions ?? this.$data.settings.orders_terms_and_conditions
    this.$data.settings.orders_qualifiers = settings?.orders_qualifiers ?? this.$data.settings.orders_qualifiers
    this.$data.qualifiersOnLoad = settings?.orders_qualifiers ?? this.$data.settings.orders_qualifiers
    this.$data.qualifiersOnLoad = this.chunkQualifiersOnLoad(this.$data.qualifiersOnLoad)
    this.$data.settings.orders_closed_verbiage = settings?.orders_closed_verbiage ?? this.$data.settings.orders_closed_verbiage
    this.$data.settings.use_legacy_leads_pricing = settings?.use_legacy_leads_pricing ?? this.$data.settings.use_legacy_leads_pricing
    this.$data.settings.phone_number_mask_enabled = settings?.phone_number_mask_enabled ?? this.$data.settings.phone_number_mask_enabled

    if (this.$data.settings.orders_start_date) {
      this.$data.settings.orders_start_date = this.convertFromUtcToEventTimezone(this.$data.settings.orders_start_date)
    }
    if (this.$data.settings.orders_end_date) {
      this.$data.settings.orders_end_date = this.convertFromUtcToEventTimezone(this.$data.settings.orders_end_date)
    }
  }

  private chunkQualifiersOnLoad (qualifiers: string[]) {
    const chunkedQualifiers: string[][] = []
    let counter = 0
    let tempArr: string[] = []
    for (let i = 0; i < qualifiers.length; i++) {
      tempArr.push(qualifiers[i])
      counter++
      if (counter === 4) {
        chunkedQualifiers.push(tempArr)
        tempArr = []
        counter = 0
      }
    }
    if (tempArr.length) {
      chunkedQualifiers.push(tempArr)
    }
    return chunkedQualifiers
  }

  // TODO: this should use the new module store to check if Leads is active.
  // TODO: lift this into the main module file?
  async beforeRouteEnter (from, to, next) {
    const response = await Container.get(EventService).getEventModules(from.params.event_uuid)
    if (response?.data) {
      const leadsModuleActive = response.data.LEADS.enabled
      if (leadsModuleActive) {
        next()
      } else {
        const message = 'Lead Retrieval Module not activated. Please, activate it and try again...'
        router.push({ name: 'level-two.event.modules', params: { uuid: from.params.uuid, event_uuid: from.params.event_uuid } }, () => {
          Container.get(Notification).error(message)
        })
      }
    }
    next(false)
  }

  @Watch('option_groups')
  onOptionsGroupsChange (optionGroups: any[]) {
    optionGroups.map(optionGroup => {
      if (optionGroup.name === 'Lead Retrieval Activations') {
        this.$data.mobileAppEditedOptions = optionGroup.options
      }
    })
  }

  async mounted () {
    try {
      this.$data.loading = true
      await this.fetchEvent()
      this.$store.dispatch('common/showLoader', { value: true })
      const promises: Promise<any>[] = [this.fetchOptionsGroup(), this.fetchSettings(), this.fetchChangeLogData(), this.fetchPricingTiers()]
      await Promise.all(promises)
      this.earlyBirdEditedOptions = this.setEarlyBirdOptions(this.earlyBirdOptions)
      this.$data.mobileAppEditedOptions = this.mobileAppOptions
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
      this.$data.loading = false
    }
  }

  async fetchEvent () {
    try {
      await this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  handleClose () {
    const form = this.$refs.addQualifierForm as HTMLFormElement
    const observer = this.$refs.addQualifier as any
    this.$data.qualifierToAdd = ''
    this.$data.qualifierToAddDialog = false
    form.reset()
    observer.reset()
  }

  addQualifier () {
    this.$data.settings.orders_qualifiers.push(this.$data.qualifierToAdd)
    if (this.$data.qualifiersOnLoad[this.$data.qualifiersOnLoad.length - 1].length === 4) {
      this.$data.qualifiersOnLoad.push([this.$data.qualifierToAdd])
    } else {
      this.$data.qualifiersOnLoad[this.$data.qualifiersOnLoad.length - 1].push(this.$data.qualifierToAdd)
    }
    this.handleClose()
  }

  async fetchSettings () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/fetchSettings', this.$route.params.event_uuid)
    } catch (error) {
      if (error.status === 404) {
        await this.fetchSettingsMetadata()
      } else {
        Container.get(ErrorHandlerService).error(error)
      }
    } finally {
      this.$data.loading = false
    }
  }

  async fetchSettingsMetadata () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/fetchSettingsMetadata')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async updateSettings () {
    try {
      const data = {
        orders_active: {
          [this.$data.registrationTypeKey]: this.$data.settings.orders_active
        },
        orders_start_date: {
          [this.$data.registrationTypeKey]: this.convertFromEventTimezoneToUtc(this.$data.settings.orders_start_date)
        },
        orders_end_date: {
          [this.$data.registrationTypeKey]: this.convertFromEventTimezoneToUtc(this.$data.settings.orders_end_date)
        },
        orders_charge_type: {
          [this.$data.registrationTypeKey]: this.$data.settings.orders_charge_type
        },
        orders_scan_format: {
          [this.$data.registrationTypeKey]: this.$data.settings.orders_scan_format
        },
        orders_statement_descriptor: {
          [this.$data.registrationTypeKey]: this.$data.settings.orders_statement_descriptor
        },
        orders_terms_and_conditions_enabled: {
          [this.$data.registrationTypeKey]: this.$data.settings.orders_terms_and_conditions_enabled
        },
        orders_terms_and_conditions: {
          [this.$data.registrationTypeKey]: this.$data.settings.orders_terms_and_conditions
        },
        orders_qualifiers: {
          [this.$data.registrationTypeKey]: this.$data.settings.orders_qualifiers
        },
        orders_closed_verbiage: {
          [this.$data.registrationTypeKey]: this.$data.settings.orders_closed_verbiage
        },
        use_legacy_leads_pricing: {
          [this.$data.registrationTypeKey]: this.$data.settings.use_legacy_leads_pricing
        },
        phone_number_mask_enabled: {
          [this.$data.registrationTypeKey]: this.$data.settings.phone_number_mask_enabled
        }
      }
      this.$data.loading = true
      const promises: Promise<any>[] = [this.editPricingTier(), this.editLrOptions(), this.editMobileAppOptions()]
      await Promise.all(promises)
      await this.$store.dispatch('leads/updateSettings', { event_uuid: this.$route.params.event_uuid, data })
      Container.get(Notification).success('Settings successfully updated')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      const promises: Promise<any>[] = [this.fetchOptionsGroup(), this.fetchChangeLogData()]
      await Promise.all(promises)
      this.$data.loading = false
    }
  }

  async fetchOptionsGroup () {
    try {
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  get leadRetrievalDevicesOptionGroup () {
    const [group] = (this.option_groups || []).filter(group => group.name === 'Lead Retrieval Devices')
    return group
  }

  get leadRetrievalActivatonsOptionGroup () {
    const [group] = (this.option_groups || []).filter(group => group.name === 'Lead Retrieval Activations')
    return group
  }

  get earlyBirdOptions () {
    if (this.$data.settings.use_legacy_leads_pricing) {
      return (this.leadRetrievalDevicesOptionGroup?.options || []).filter(option => ['proscanner', 'lead retrieval mobile app'].includes(option.name.toLowerCase()))
    } else {
      return (this.leadRetrievalDevicesOptionGroup?.options || []).filter(option => ['proscanner'].includes(option.name.toLowerCase()))
    }
  }

  setEarlyBirdOptions (options: any = []) {
    const optionSpecificDates = this.earlyBirdPricingTier?.option_specific_dates || {}
    if (options.length) {
      for (const option of options) {
        const optionExtraContent = this.$data.devicesExtraContent?.[option.name.toLowerCase()] || {}
        option.description = optionExtraContent?.description || ''
        option.editedData = {
          active: false,
          firstDevicePrice: 0,
          additionalDevicePrice: 0,
          earlyBirdActive: false,
          earlyBirdFirstDevicePrice: 0,
          earlyBirdAdditionalDevicePrice: 0,
          earlyBirdStartDate: '2000-01-01 00:00:00',
          earlyBirdEndDate: ''
        }
        option.editedData.active = !!option.visible
        const [pricing] = (option?.pricing || []).filter(obj => obj.reg_type === this.$data.registrationTypeKey)
        if (pricing && Object.keys(pricing).length) {
          // sometimes _default is not in the pricing payload, so check for it first.
          if ('_default' in pricing.tiered_pricing) {
            option.editedData.firstDevicePrice = pricing.tiered_pricing._default.qty_pricing['1']
            option.editedData.additionalDevicePrice = pricing.tiered_pricing._default.qty_pricing['2-1000']
          }
          // check for the standardPricing uuid next, which will overwrite _default.
          if (this.standardPricingTierUuid in pricing.tiered_pricing) {
            option.editedData.firstDevicePrice = pricing.tiered_pricing[this.standardPricingTierUuid].qty_pricing['1']
            option.editedData.additionalDevicePrice = pricing.tiered_pricing[this.standardPricingTierUuid].qty_pricing['2-1000']
          }
          // check for the earlyBirdPricing uuid.
          if (this.earlyBirdPricingTierUuid in pricing.tiered_pricing) {
            option.editedData.earlyBirdActive = true
            option.editedData.earlyBirdFirstDevicePrice = pricing.tiered_pricing[this.earlyBirdPricingTierUuid].qty_pricing['1']
            option.editedData.earlyBirdAdditionalDevicePrice = pricing.tiered_pricing[this.earlyBirdPricingTierUuid].qty_pricing['2-1000']
            option.editedData.earlyBirdEndDate = this.convertFromEventTimezoneToUtc(this.earlyBirdPricingTier.date_end)
            if (optionSpecificDates?.[option.uuid]) {
              option.editedData.earlyBirdStartDate = '2000-01-01 00:00:00'
              option.editedData.earlyBirdEndDate = this.convertFromUtcToEventTimezone(optionSpecificDates[option.uuid].date_end)
            }
          }
          Object.assign(option, {
            image: optionExtraContent.image
          })
        }
      }
    }
    // if use legacy leads pricing is true then we need to remove the mobile app option from the early bird options
    if (!this.$data.settings.use_legacy_leads_pricing) {
      options = options.filter(option => option.name.toLowerCase() !== 'lead retrieval mobile app')
      this.$data.legacyPricing = false
    } else {
      this.$data.legacyPricing = true
    }
    return options
  }

  get mobileAppOptions () {
    return (this.leadRetrievalActivatonsOptionGroup?.options || [])
  }

  private async fetchPricingTiers () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('pricing/getLrPricingTiers', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get standardPricingTierUuid () {
    return (this as any).lr_pricing_tiers.filter(tier => tier.name === 'Standard')?.[0]?.uuid
  }

  get earlyBirdPricingTierUuid () {
    return (this as any).lr_pricing_tiers.filter(tier => tier.name === 'Early Bird')?.[0]?.uuid
  }

  get earlyBirdPricingTier () {
    return (this as any).lr_pricing_tiers.filter(tier => tier.name === 'Early Bird')?.[0] ?? null
  }

  earlyBirdOptionSpecificDates () {
    const utcDeviceDates = {}
    for (const device of this.earlyBirdEditedOptions) {
      if (device.editedData.earlyBirdStartDate && device.editedData.earlyBirdEndDate && device.editedData.earlyBirdActive) {
        utcDeviceDates[device.uuid] = {}
        utcDeviceDates[device.uuid].date_start = '2000-01-01 00:00:00'
        utcDeviceDates[device.uuid].date_end = this.convertFromEventTimezoneToUtc(device.editedData.earlyBirdEndDate)
      }
    }
    return utcDeviceDates
  }

  standardOptionSpecificDates () {
    const utcDeviceDates = {}
    for (const device of this.earlyBirdEditedOptions) {
      const earlyBirdEndDate = new Date(new Date(device.editedData.earlyBirdEndDate).getTime() + 1000)
      if (device.editedData.earlyBirdStartDate && device.editedData.earlyBirdEndDate && device.editedData.earlyBirdActive) {
        utcDeviceDates[device.uuid] = {}
        utcDeviceDates[device.uuid].date_start = this.convertFromEventTimezoneToUtc(earlyBirdEndDate)
        utcDeviceDates[device.uuid].date_end = '2100-01-01 00:00:00'
      }
    }
    return utcDeviceDates
  }

  @Watch('earlyBirdPricingTier', { immediate: true })
  onEarlyBirdPricingTierChange (payload: any) {
    if (payload) {
      this.$data.earlyBirdStart = this.convertFromUtcToEventTimezone(payload.date_start)
      this.$data.earlyBirdDeadline = this.convertFromUtcToEventTimezone(payload.date_end)
    }
  }

  async editPricingTier () {
    try {
      if (this.$data.earlyBirdDeadline) {
        const standardStart = moment(this.$data.earlyBirdDeadline).add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        const standardEnd = moment(standardStart).add(1, 'years').format('YYYY-MM-DD HH:mm:ss')
        const earlyStart = moment(this.$data.earlyBirdDeadline).subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss')
        const earlyEnd = moment(this.$data.earlyBirdDeadline).format('YYYY-MM-DD HH:mm:ss')
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          type: 'LRORDERS',
          data: {
            tiers: [
              {
                name: 'Early Bird',
                uuid: this.earlyBirdPricingTierUuid,
                date_start: this.convertFromEventTimezoneToUtc(earlyStart),
                date_end: this.convertFromEventTimezoneToUtc(earlyEnd),
                option_specific_dates: this.earlyBirdOptionSpecificDates()
              },
              {
                name: 'Standard',
                uuid: this.standardPricingTierUuid,
                date_start: this.convertFromEventTimezoneToUtc(standardStart),
                date_end: this.convertFromEventTimezoneToUtc(standardEnd),
                option_specific_dates: this.standardOptionSpecificDates()
              }
            ]
          }
        }
        await this.$store.dispatch('pricing/editPricingTierBulk', payload)
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async editMobileAppOptions () {
    try {
      for (const device of this.$data.mobileAppEditedOptions) {
        const payload = {
          event_uuid: this.$route.params.event_uuid,
          option_group_uuid: this.leadRetrievalActivatonsOptionGroup.uuid,
          option_uuid: device.uuid,
          data: {
            display_order: device.name === 'Unlimited' ? 1000 : device.name,
            name: device.name,
            visible: 1,
            pricing: device.pricing
          }
        }
        await this.$store.dispatch('option/editOption', payload)
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async editLrOptions () {
    try {
      if (this.earlyBirdEditedOptions.length) {
        for (const device of this.earlyBirdEditedOptions) {
          const tiered_pricing: any = {
            [this.standardPricingTierUuid]: {
              qty_pricing: {
                1: device.editedData.firstDevicePrice,
                '2-1000': device.editedData.additionalDevicePrice
              },
              cancel_price: 0
            }
          }
          if (device.editedData.earlyBirdActive) {
            tiered_pricing[this.earlyBirdPricingTierUuid] = {
              qty_pricing: {
                1: device.editedData.earlyBirdFirstDevicePrice,
                '2-1000': device.editedData.earlyBirdAdditionalDevicePrice
              },
              cancel_price: 0
            }
          }
          const payload = {
            event_uuid: this.$route.params.event_uuid,
            option_group_uuid: this.leadRetrievalDevicesOptionGroup.uuid,
            option_uuid: device.uuid,
            data: {
              name: device.name,
              visible: device.editedData.active ? 1 : 0,
              pricing: [
                {
                  reg_type: this.$data.registrationTypeKey,
                  tiered_pricing
                }
              ]
            }
          }
          await this.$store.dispatch('option/editOption', payload)
        }
        await this.fetchOptionsGroup()
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  get orderFormUrl () {
    if (this.event) {
      return `${process.env.VUE_APP_REG_URL}/${this.event.event_identifier}/leads/`
    }
    return ''
  }

  onCopy () {
    Container.get(Notification).success('Successfully copied link.')
  }

  onError () {
    Container.get(Notification).success('There was an error copying the link. Please refresh and try again.')
  }

  async handleCloseAddActivationForm () {
    this.$data.forms.showAddActivationOptionForm = false
    await this.fetchOptionsGroup()
  }

  handleOpenAddActivationForm () {
    this.$data.forms.showAddActivationOptionForm = true
  }

  async deleteOption (option) {
    try {
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        option_group_uuid: this.leadRetrievalDevicesOptionGroup.uuid,
        option_uuid: option.uuid
      }
      await this.$store.dispatch('option/deleteOption', payload)
      Container.get(Notification).success('Deleted Option Succesfully.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.fetchOptionsGroup()
    }
  }
}
